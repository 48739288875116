import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import Clickable from "../../../components/Clickable";

import BGImg from "../../../img/jpg/home-partners-bg.jpg";
import Logo from "../../../img/svg/home/logo-partners.svg";
import BMWLogo from "../../../img/svg/home/bmw-logo.svg";
import MBLogo from "../../../img/svg/home/mb-logo.svg";
import CartierLogo from "../../../img/svg/home/cartier-logo.svg";
import EmiratesLogo from "../../../img/svg/home/emirates-logo.svg";
import GucciLogo from "../../../img/svg/home/gucci-logo.svg";
import BugattiLogo from "../../../img/svg/home/bugatti-logo.svg";
import buttons from "../../../styles/buttons";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const items = [
  BugattiLogo,
  CartierLogo,
  BMWLogo,
  GucciLogo,
  EmiratesLogo,
  MBLogo,
];

const OurPartners = ({homeText, homeImage}) => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "partnerLogo"] |order(_createdAt asc) {
        logo[] {
          logo,
          image{
            asset ->{
              _id,
              url
            }
          },
        }
      }`
    ).then((data) => {
      setLogos(data);
    }).catch(console.error);
  },[])

  return (
    <div style={{ position: "relative" }}>
      <div
        className={style.bg}
        style={{ backgroundImage: `url(${homeImage[0] && homeImage.filter(item => item.location == "partner background image")[0].image.asset.url})` }}
      ></div>
      <div className={style.container}>
        <img src={Logo} className="logo" alt="logo" />
        <h2 style={{ whiteSpace: 'pre-line'}}>
        {homeText[0] && homeText.filter(item => item.type == "partners")[0].title}
        </h2>
        <p>
          {homeText[0] && homeText.filter(item => item.type == "partners")[0].content}
        </p>
        <div className="items">
          {logos[0] && logos[0].logo.map((img, key) => {
            return (
              <div key={key} className="item">
                <a href={key === 0 ? "https://extraordinary.college" : key === 1 ? "https://www.habsburgfinearts.com/theportfolio" : "#"} target="_blank" rel="noopener noreferrer">
                  <img src={img.image.asset.url} alt="" />
                </a>
              </div>
            );
          })}
        </div>
        <Clickable 
          className={buttons.partner} 
          onClick={() => window.location.href = 'mailto:hyperringco@gmail.com'}
        >
          PARTNER WITH US
        </Clickable>
      </div>
    </div>
  );
}

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    opacity: 0.95,
    position: "absolute",
    backgroundSize: "inherit",
    left: 0,
    top: 0,
    zIndex: 0,

    "&:before": {
      background:
        "linear-gradient(to top, rgb(247 247 247 / 0%) 0%, rgb(245 245 245) 50%, rgb(245 245 245) 100%)",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 200,
    },

    "&:after": {
      background: 'linear-gradient(to top, rgb(247 247 247) 0%, rgb(245 245 245) 50%, rgba(0, 0, 0, 0) 100%)',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 200,
    },
  }),

  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    paddingTop: 100,
    paddingBottom: 160,
    zIndex: 1,
    position: 'relative',

    h2: {
      // color: "#1B243A",
      color: "#000",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      fontFamily: "Halvar Breitschrift",
      fontSize: 100,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-4px",
      // textTransform: "lowercase",
      marginBottom: 60,
    },
    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      textAlign: "center",
      // fontSize: 18,
      // lineHeight: "30px",
      // background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      // backgroundClip: "text",
      // WebkitBackgroundClip: "text",
      // WebkitTextFillColor: "transparent",
      marginBottom: 80,
      maxWidth: 1000,
    },

    ".logo": {
      margin: "auto",
      marginBottom: 10,
      width: 32.476,
      height: 47.238,
    },
    ".items": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginBottom: 80,
      gap: 60,

      ".item": {
        img: {
          display: "block",
          maxHeight: 180,
          maxWidth: 180,
          width: "100%",
          height: "100%",
        },
      },
    },
    [laptop]: {
      h2: {
        fontSize: 90,
        letterSpacing: "-3.6px",
        marginBottom: 50,
      },
    },
    [tablet]: {
      padding: 30,
      paddingTop: 80,
      paddingBottom: 120,
      h2: {
        fontSize: 75,
        letterSpacing: "-3px",
        marginBottom: 50,
      },
      p: {
        fontSize: 16,
        lineHeight: "28px",
      },
    },
    [mobile]: {
      padding: 20,
      paddingTop: 60,
      paddingBottom: 80,

      h2: {
        fontSize: 46,
        letterSpacing: "-1.84px",
        marginBottom: 30,
      },
      p: {
        fontSize: 14,
        lineHeight: "24px",
      },
      ".logo": {
        margin: "auto",
        marginBottom: 10,
        width: 20.681,
        height: 30.081,
      },
    },
  }),
};

export default OurPartners;
