import { css } from "@emotion/css";
import { laptop, mobile, tablet } from "../../styles/breakpoints";

const style = {
  overlay: css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 99,
    pointerEvents: "auto",
  }),
  container: css({
    backgroundColor: "#f5f5f5",
    position: "fixed",
    zIndex: 100,
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "0px 80px 0px 80px",
    borderRadius: 40,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    [laptop]: {
      width: "60%",
      padding: "0px 40px 0px 40px",
    },
    [tablet]: {
      width: "70%",
      padding: "0px 40px 0px 40px",
    },
    [mobile]: {
      width: "80%",
      padding: "0px 20px 0px 20px",
    },
    ".close": {
      position: "absolute",
      right: 40,
      top: 20,
      "&:hover": {
        cursor: "pointer",
        opacity: 0.5,
      },
    },
    h2: {
      color: "#000",
      fontFamily: "Halvar Breitschrift",
      fontSize: 34,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      zIndex: 1,
    },
    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      // color: "#000",
      // fontFamily: "Suisse Intl",
      // fontSize: 20,
      // fontStyle: "normal",
      // fontWeight: 400,
      // lineHeight: "34px",
      letterSpacing: "-0.2px",
      marginTop: 20,
    },
  }),
  textContainer: css({
    width: "60%",
    padding: "40px 0px 40px 0px",
    [laptop]: {
      width: "100%",
    },
    [tablet]: {
      width: "100%",
    },
    [mobile]: {
      width: "100%",
      padding: "20px 0px 20px 0px",
    },
  }),
  flex: css({
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    alignItems: "center",
    [laptop]: {
      flexDirection: "column-reverse",
    },
    [tablet]: {
      flexDirection: "column-reverse",
    },
    [mobile]: {
      flexDirection: "column-reverse",
    },
  }),
  imageContainer: css({
    width: "200px",
    height: "200px",
    backgroundImage: "url(/popup.png)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [laptop]: {
      width: "100px",
      height: "100px",
    },
    [tablet]: {
      width: "100px",
      height: "100px",
    },
    [mobile]: {
      width: "100px",
      height: "100px",
    },
  }),
};

export default style;
