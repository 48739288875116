import React, { useEffect, useState, useRef } from "react";
import { css } from "@emotion/css";
import { mobile, tablet } from "../../styles/breakpoints";
import { client } from "../../lib/client";
import { useParams } from "react-router-dom";

const PrivacyPage = () => {
  const {section} = useParams();
  const sectionRef = {
    faq: useRef(null),
    terms: useRef(null),
    cancellation: useRef(null),
    shipping: useRef(null),
    exchange: useRef(null),
    payment: useRef(null),
  }
    const [privacy, setPrivacy] = useState("");
    const [faq, setFaq] = useState("");
    const [terms, setTerms] = useState("");
    const [cancellation, setCancellation] = useState("");
    const [shipping, setShipping] = useState("");
    const [exchange, setExchange] = useState("");
    const [payment, setPayment] = useState("");


    useEffect(() => {
        client.fetch(
        `*[_type == "privacy"] {
            type,
            privacy,
        }`
        ).then((data) => {
          setPrivacy(data.filter(item => item.type === 'Privacy')[0].privacy);
          setFaq(data.filter(item => item.type === 'FAQ')[0].privacy);
          setTerms(data.filter(item => item.type === 'Terms')[0].privacy);
          setCancellation(data.filter(item => item.type === 'Cancellation')[0].privacy);
          setShipping(data.filter(item => item.type === 'Shipping')[0].privacy);
          setExchange(data.filter(item => item.type === 'Return')[0].privacy);
          setPayment(data.filter(item => item.type === 'Payment')[0].privacy);

          setTimeout(() => {
            if(section && sectionRef[section] && sectionRef[section].current){
              sectionRef[section].current.scrollIntoView({behavior:'smooth'});
            }else{
              window.scrollTo({top:0, behavior:'smooth'});
            }
          }, 300);
        }).catch(console.error);
    }, [section]);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <p className={styles.content}>
          {privacy}
        </p>
        <section ref={sectionRef.faq} id="faq">
          <h1 className={styles.title}>FAQ</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: faq }}
          />
        </section>
        <section ref={sectionRef.terms} id="terms">
          <h1 className={styles.title}>Terms And Conditions</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: terms }}
          />
        </section>
        <section ref={sectionRef.cancellation} id="cancellation">
          <h1 className={styles.title}>Cancellation Policy</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: cancellation }}
          />
        </section>
        <section ref={sectionRef.shipping} id="shipping">
          <h1 className={styles.title}>Shipping Information</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: shipping }}
          />
        </section>
        <section ref={sectionRef.exchange} id="exchange">
          <h1 className={styles.title}>Return and Exchange Policy</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: exchange }}
          />
        </section>
        <section ref={sectionRef.payment} id="payment">
          <h1 className={styles.title}>Payment Options</h1>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: payment }}
          />
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    justifyContent: "center",
    padding: "100px 20px", // 상단 여백을 줍니다.
    minHeight: "100vh", // 페이지가 화면 전체를 차지하도록 설정합니다.
  }),
  contentWrapper: css({
    maxWidth: "1300px", // 콘텐츠의 최대 너비를 설정합니다.
    width: "100%",
    padding: "40px",
    borderRadius: "8px", // 모서리를 둥글게 만듭니다.
  }),
  title: css({
    // fontSize: "32px",
    // color: "#1B243A",
    // marginBottom: "40px",
    // textAlign: "center",
    // fontFamily:"Vollkorn,serif",
    // fontWeight: 600,
    // fontSize: 72,
    color: "#000",
    fontFamily: "Halvar Breitschrift",
    fontSize: 100,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "105%",
    letterSpacing: "-4px",
    //textTransform: "lowercase",
    background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    marginBottom: 45,

    [tablet]:{
        fontSize: "34px",
        h2: {
          fontSize: 36,
          letterSpacing: "-1.44px",
        },
    }
  }),
  content: css({
    whiteSpace: "pre-line",
    color: "rgba(27, 36, 58, 0.80)",
    fontFamily: "Suisse Intl",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    marginBottom:100,
    // maxWidth: 800,

    [tablet]:{
      fontSize: 16,
      lineHeight: "28px",
    }
  }),
};

export default PrivacyPage;
