import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { css } from "@emotion/css";

import MenuIcon from "../img/svg/menu-icon.svg";
import MenuIconBlue from "../img/svg/menu-icon-blue.svg";
import CloseIcon from "../img/svg/close-button.svg";
import BG from "../img/jpg/navigation-bg.jpg";
import IGIcon from "../img/svg/ig-icon.svg";
import TGIcon from "../img/svg/tg-icon.svg";
import TWIcon from "../img/svg/tw-icon.svg";
import { laptop, mobile } from "../styles/breakpoints";
import Clickable from "./Clickable";
import { client } from "../lib/client";

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [open, setOpen] = useState(false);
  const [whitePaper, setWhitePaper] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "header"] {
        title,
        file{
          asset ->{
            _id,
            url
          }
        },
      }`
    ).then((data) => {
      setWhitePaper(data);
    }).catch(console.error);
  },[])

  useEffect(() => {
      setOpen(false);
  }, [pathname]);

  const style = {
    container: css({
      position: "absolute",
      left: 0,
      top: 0,
      maxWidth: 1830,
      paddingTop: 14,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "flex-start",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      zIndex: 2,

      a: {
        color: location.pathname === "/" ? "#ffffff" : "#1B243A",
      },
      ".wrapper": {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        alignContent: "center",
        flexWrap: "nowrap",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: 40,
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 20,

        ".logo": {
          marginRight: "auto",
        },
      },

      ".top": {
        padding: 30,
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
      },
      ".bottom": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
        padding: 30,
      },
      ".middle": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "flex-start",
      },
      ".shopping-cart": {
        fontFamily: "Suisse Intl",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "26.923px",
        textTransform: "capitalize",
        zIndex: 1,
        color: "white",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      },

      ".copy": {
        color: "#1B243A",
        fontFamily: "Suisse Intl",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: 0.64,
        textAlign: "right",
        lineHeight: "30px",
      },
      ".hypper-ring": {
        fontFamily: "Suisse Intl",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 400,
        color: "#1B243A",
        lineHeight: "28px",
      },

      ".social": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
        padding: 30,

        img: {
          width: 41,
          height: 41,
        },
      },
      ".menu": {
        padding: 30,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gap: 40,
        width: "100%",
        maxHeight: 400,
        height: "100%",

        a: {
          color: "#1B243A",
          fontFamily: "Halvar Breitschrift",
          fontSize: 52,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
          letterSpacing: "-2.08px",
    //      textTransform: "uppercase",
        },

        hr: {
          display: "none",
        },
      },

      ".nav-mobile": {
        backgroundImage: `url(${BG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        transition: "all 1s ease",
        width: "100%",
        zIndex: 9999,
        position: "fixed",
        left: -1200,
        top: 0,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "stretch",
        height: '100%',
        overflow: 'auto',

        "&.active": {
          left: 0,
        },
      },

      ".mobile": {
        display: "none",
      },
      [laptop]: {
        ".desktop": {
          display: "none",
        },

        ".mobile": {
          display: "flex",
        },
        ".wrapper": {
          paddingLeft: 30,
          paddingRight: 30,
        },
        ".menu": {
          padding: 30,
          display: "grid",
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr",
          gap: 30,
          width: "100%",
          marginBottom: 60,

          a: {
            marginBottom: 0,
          },
          hr: {
            display: "block",
            height: 0,
            width: "40%",
            borderTop: "1px solid #E2E9FA",
          },
        },
      },
      [mobile]: {
        ".wrapper": {
          paddingLeft: 20,
          paddingRight: 20,
        },
        ".menu": {
          padding: 20,
          gap: 20,

          a: {
            fontSize: 36,
            letterSpacing: "-1.44px",
          },
        },

        ".social": {
          gap: 20,
          padding: 20,

          img: {
            width: 32,
            height: 32,
          },
        },

        ".top": {
          padding: 20,
          paddingTop: 56,

          ".shopping-cart": {
            span: {
              display: "none",
            },
          },
        },
        ".bottom": {
          padding: 20,
          paddingBottom: 56,
          flexWrap: 'wrap',
        },

      ".copy": {
        fontSize: 14,
        lineHeight: "18px",
        marginBottom: 20,
      },
      ".hypper-ring": {
        fontSize: 20,
        lineHeight: "24px",
        marginBottom: 20,
      },
      },
    }),
  };

  return (
    <header className={style.container}>
      <div className="wrapper desktop">
        <Link to="/" className="logo">
          HyperRing
        </Link>
        <Link to="/technology">Technology</Link>
        <Link to="https://shop.hyperring.co">Shop</Link>
        <Link to="/team">Team</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/faq">FAQ</Link>
        <Link to={whitePaper[0] && whitePaper.filter(item => item.title === "whitepaper")[0].file.asset.url} target="blank">Whitepaper</Link>
        {/* <Link to="/shop">Shopping bag</Link>
        <Link to="/shop">
          <img
            src={location.pathname === "/" ? CartIcon : CartIconBlue}
            alt="cart"
          />
        </Link> */}
      </div>
      <div className="wrapper mobile">
        <Link to="/" className="logo">
          HyperRing
        </Link>

        <Clickable onClick={() => setOpen(!open)} className="menu-button">
          <img src={location.pathname === "/" ? MenuIcon : MenuIconBlue} width="24" height="20" alt="" />
        </Clickable>

        <div className={`nav-mobile${open ? " active" : ""}`}>
          <div className="top">
            <Clickable onClick={() => setOpen(!open)} className="close-button">
              <img src={CloseIcon} width="25" height="25" alt="" />
            </Clickable>

            {/* <Link to="/shop" className="shopping-cart">
              <img src={CartIcon} alt="cart" width={24.852} height={28.994} />
              <span>Shopping bag</span>
            </Link> */}
          </div>

          <div className="middle">
            <div className="social">
              <Clickable>
                <img src={TWIcon} alt="twitter" />
              </Clickable>
              <Clickable>
                <img src={IGIcon} alt="instagram" />
              </Clickable>
              <Clickable>
                <img src={TGIcon} alt="telegram" />
              </Clickable>
            </div>

            <div className="menu">
              <Link to="/technology">Technology</Link>
              <Link to="https://shop.hyperring.co">Shop</Link>
              <hr />
              <Link to="/team">Team</Link>
              <Link to="/contact">Contact</Link>
              <hr />
              <Link to="/faq">FAQ</Link>
              <Link to={whitePaper[0] && whitePaper.filter(item => item.title === "whitepaper")[0].file.asset.url} target="blank">Whitepaper</Link>
            </div>
          </div>

          <div className="bottom">
            <Link to="/" className="hypper-ring">
              HyperRing
            </Link>

            <div className="copy">
              Powered by Hypercycle
              <br />
              2024 © HyperRing
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
