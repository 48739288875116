import { X } from "lucide-react";
import React, { useEffect } from "react";
import buttons from "../../styles/buttons";
import Clickable from "../Clickable";
import style from "./Popup.style";

const Popup = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className={`${style.overlay}`}></div>
      <div className={`${style.container}`}>
        <div className={`close`} onClick={onClose}>
          <X />
        </div>
        <div className={`${style.flex}`}>
          <div className={`${style.textContainer}`}>
            <h2>The 2nd Gen</h2>
            <h2>HyperRing is Here!</h2>
            <p>
              Now with payments enabled, the 2nd Gen HyperRing is secure,
              contactless, and always ready. Preorder now and leave your wallet
              behind.
            </p>
            <a
              href="https://shop.hyperring.co/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
              }}
            >
              <Clickable className={buttons.popup}>Pre-Order</Clickable>
            </a>
          </div>
          <div className={`${style.imageContainer}`}>
            {/* <img src={ring} alt="" /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
