import React, { useEffect, useState } from 'react';

import { css } from "@emotion/css";
import BGImg from "../../../img/jpg/more-bg.jpg";
import buttons from "../../../styles/buttons";
// import { products } from "../../../helpers/products";
import { Link } from "react-router-dom";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from '../../../lib/client';

const More = ({ skip = null }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "product"] {
          id,
          image{
            asset ->{
              _id,
              url
            }
          },
          gallery[]{
            image{
              asset ->{
                _id,
                url
              }
            }
          },
          slug,
          title,
          subtitle,
          price,
          description,
      }`
      ).then((data) => {
        setProducts(data);
      }).catch(console.error);
  },[]);

  return (
  <div style={{ position: "relative" }}>
    <div
      className={style.bg}
      style={{ backgroundImage: `url(${BGImg})` }}
    ></div>
    <div
      className={`${style.container}${
        window.location.pathname.includes("shop") ? " shop" : ""
      }`}
    >
      <h2>You May Also Like</h2>
      <div className="wrapper">
        {Object.values(products)
          .filter((f) => f.id !== skip)
          .map((v, k) => (
            <div className="product-wrapper" key={k}>
              <div className="product">
                <img src={v.image.asset.url} alt={v.title} />
                <h4>{v.title}</h4>
                <p>{v.subtitle}</p>
                <span>USD {v.price}</span>
              </div>
              <Link className={buttons.primary} to={`/product/${v.slug}`}>
                <span>+ Sold Out</span>
              </Link>
            </div>
          ))}
      </div>
    </div>
  </div>
);}

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "cover",
    left: 0,
    top: 0,
  }),
  container: css({
    width: "100%",
    display: "flex",
    paddingBottom: 80,
    paddingTop: 80,
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,

    h2: {
      color: "#1B243A",
      textAlign: "center",
      fontFamily: "Halvar Breitschrift",
      fontSize: 100,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-4px",
    //  textTransform: "lowercase",
      marginBottom: 127,
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      maxWidth: 1200,
      whiteSpace: "nowrap", // 추가된 코드
    },

    ".wrapper": {
      maxWidth: 1240,
      height: "100%",
      margin: 40,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "stretch",
      gap: 28,

      ".product-wrapper": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 40,
        maxWidth: 407,

        ".product": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 40,
          background: "rgba(245, 245, 246, 0.50)",
          backdropFilter: "blur(10px)",
          padding: 35,
          height: "100%",

          img: {
            width: "100%",
          },
          h4: {
            color: "#1B243A",
            textAlign: "center",
            fontFamily: "Halvar Breitschrift",
            fontSize: 20,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          //  textTransform: "uppercase",
            marginBottom: 10,
          },
          p: {
            color: "#6D7DA1",
            textAlign: "center",
            fontFamily: "Suisse Intl",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginBottom: 28,
          },
          span: {
            padding: "8px 20px",
            alignItems: "center",
            borderRadius: 88.095,
            border: "0.881px solid #6D7DA1",
            color: "#6D7DA1",
            fontFamily: "Suisse Intl",
            fontSize: 17,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
   //         textTransform: "uppercase",
          },
        },
      },
    },
    [desktop]: {
      h2: {
        fontSize: 75,
        letterSpacing: "-3px",
      },
    },

    [laptop]: {
      h2: {
        fontSize: 68,
        letterSpacing: "-2.75px",
        marginBottom: 110,
      },
    },

    [tablet]: {
      paddingTop: 0,
      h2: {
        fontSize: 46,
        letterSpacing: "-1.84px",
        marginBottom: 80,
        marginLeft: 30,
        marginRight: 30,
      },
    },
    [mobile]: {
      h2: {
        fontSize: 36,
        letterSpacing: "-1.44px",
        marginBottom: 60,
        textAlign: 'left',
        marginLeft: 20,
        marginRight: 20,
      },
    },
  }),
};

export default More;
