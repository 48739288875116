import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import SizeGuide from "./SizeGuide";
import { css } from "@emotion/css";
import Clickable from "../../../components/Clickable";
import BGImg from "../../../img/jpg/product-bg.jpg";
import ArrowRightIcon from "../../../img/svg/gallery-arrow.svg";
import ExpandIcon from "../../../img/svg/arrow-expand.svg";
import buttons from "../../../styles/buttons";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";

const Listing = ({ item }) => {
  const [active, setActive] = useState(0);
  const [expandMobile, setExpandMobile] = useState(false);
  const [expandNoticeMobile, setExpandNoticeMobile] = useState(false);
  const [showSizeGuide, setShowSizeGuide] = useState(false); // SizeGuide modal 상태 추가
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [item]);

  useEffect(() => {
    console.log('showSizeGuide',showSizeGuide)
  },[showSizeGuide])

  useEffect(() => {
    if (showSizeGuide) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showSizeGuide]);

  console.log('item',item);

  return (
    <div style={{ position: "relative" }}>
      <div
        className={style.bg}
        style={{ backgroundImage: `url(${BGImg})` }}
      ></div>
      <div
        className={`${style.container}${
          window.location.pathname.includes("shop") ? " shop" : ""
        }`}
      >
        <div className="notice desktop">
          <b>Pre-order Only:</b> This item is exclusively available for
          pre-order. Reserve yours now and pay only 5% of the retail price
          upfront.
        </div>
        <div className={`notice mobile ${expandNoticeMobile ? "active" : ""}`}>
          <div className="heading">
            <h5>Pre-order Only</h5>
            <Clickable
              className="toggle-button"
              onClick={() => setExpandNoticeMobile(!expandNoticeMobile)}
            >
              <img src={ExpandIcon} alt="" />
            </Clickable>
          </div>
          <div className="message">
            <p>
              This item is exclusively available for pre-order. Reserve yours
              now and pay only 5% of the retail price upfront.
            </p>
          </div>
        </div>
        <div className="wrapper">
          <div className="gallery">
            <div className="arrows">
              <Clickable
                className="arrow-left"
                onClick={() => setActive(active < 1 ? active : active - 1)}
              >
                <img src={ArrowRightIcon} alt="" />
              </Clickable>
              <Clickable
                className="arrow-right"
                onClick={() =>
                  setActive(
                    active < Object.keys(item.gallery).length - 1
                      ? active + 1
                      : active
                  )
                }
              >
                <img src={ArrowRightIcon} alt="" />
              </Clickable>
            </div>
            <div className="images">
              {Object.values(item.gallery).map((img, key) => (
                <img
                  key={key}
                  src={img.asset.url}
                  alt={item.title}
                  className={key === active ? "active" : ""}
                />
              ))}
            </div>
            <div className="navigator">
              {Object.values(item.gallery).map((img, key) => (
                <Clickable
                  className={key === active ? "active" : ""}
                  onClick={() => setActive(key)}
                >
                  {("0" + (key + 1)).slice(-2)}
                </Clickable>
              ))}
            </div>
          </div>
          <div className={`meta ${expandMobile ? "active" : ""}`}>
            <div className="product">
              <h4 className="i1">{item.title}</h4>
              <h5 className="i2">{item.subtitle}</h5>
              <span className="i3">USD {item.price}</span>
              <p className="i4">
                Suggested Retail <br />
                *You pay only 5% of the retail price upfront.{" "}
              </p>
            </div>
            <div className="varibles">
              <div className="varibles-wrapper">
                <label>Colour</label>
                <div className="colours">
                  <div className="colour">
                    {item.slug === "aurora-lumina-hyperring" ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                        >
                          <g filter="url(#filter0_i_301_1575)">
                            <circle
                              cx="16.5004"
                              cy="16.1999"
                              r="15.6"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_i_301_1575"
                              x="0.900391"
                              y="0.599854"
                              width="32.2002"
                              height="32.2"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dx="1" dy="1" />
                              <feGaussianBlur stdDeviation="1" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.105882 0 0 0 0 0.141176 0 0 0 0 0.227451 0 0 0 0.2 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_301_1575"
                              />
                            </filter>
                          </defs>
                        </svg>

                        <span>White</span>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="33"
                          height="32"
                          viewBox="0 0 33 32"
                          fill="none"
                        >
                          <g filter="url(#filter0_i_783_29005)">
                            <circle
                              cx="16.4999"
                              cy="16.2016"
                              r="15.6"
                              fill="#1B243A"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_i_783_29005"
                              x="0.899902"
                              y="0.601562"
                              width="32.2002"
                              height="32.2031"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dx="1" dy="1" />
                              <feGaussianBlur stdDeviation="1" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_783_29005"
                              />
                            </filter>
                          </defs>
                        </svg>

                        <span>Black</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="specs">
              <div className="specs-wrapper">
                <div className="size">
                  <label>Size</label>
                  <select name="size">
                    {[...Array(12)].map((_, index) => (
                      <option key={index} value={index + 4}>
                        {index + 4} US
                      </option>
                    ))}
                  </select>
                  <label onClick={() => setShowSizeGuide(true)}>Size Guide</label> {/* Size Guide 클릭 시 modal 열기 */}
                </div>
                <div className="qty">
                  <label>Quantity</label>
                  <input type="number" name="qty" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="order">
              <Clickable
                className={buttons.primary}
                style={{ width: "100%" }}
                onClick={() => {
                  setExpandMobile(!expandMobile);
                  // window.open(item.payLink,'_blank');
                }}
              >
                <span>+ Sold Out</span>
              </Clickable>
            </div>
            <div className="note">
              <div className="note-wrapper">
                <h5>Custom Order Notice: </h5>
                <p>
                  These rings are available for custom order with a 6-9 month
                  lead time due to ring sizing-related manufacturing logistics.
                  If we do not deliver your ring by the end of the 9th month,
                  your 5% deposit will be fully refunded.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSizeGuide && (
        <Modal
          isOpen={showSizeGuide}
          onRequestClose={() => setShowSizeGuide(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)', // dim 처리
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000, // z-index 추가
            },
            content: {
              position: 'relative',
              inset: 'auto',
              padding: '20px',
              borderRadius: '10px',
              maxWidth: '500px',
              width: '90%',
              margin: 'auto',
            },
          }}
        >
          <SizeGuide />
        </Modal>
      )}
    </div>
  );
};

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    transition: "all .4s ease",
    width: "100%",
    height: 1300,
    position: "absolute",
    backgroundSize: "cover",
    left: 0,
    top: 0,
  }),
  container: css({
    width: "100%",
    display: "flex",
    paddingBottom: 100,
    paddingTop: 100,
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",

    ".notice": {
      color: "#1B243A",
      textAlign: "center",
      fontFamily: "Suisse Intl",
      fontSize: 18,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "-0.18px",
      borderRadius: 12,
      border: "1px solid rgba(100, 115, 148, 0.06)",
      background: "rgba(255, 255, 255, 0.15)",
      backdropFilter: "blur(4px)",
      maxWidth: 1240,
      padding: 10,
      marginLeft: 30,
      marginRight: 30,

      "&.mobile": {
        display: "none",
      },
    },

    ".wrapper": {
      maxWidth: 1240,
      height: "100%",
      margin: 40,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",

      ".gallery": {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        margin: 30,

        ".arrows": {
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: "auto",
          width: "100%",
          zIndex: 1,
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "space-between",
          alignItems: "center",
          ".arrow-left": {
            transform: "rotate(180deg)",
          },
        },
        ".images": {
          zIndex: 0,
          width: "100%",
          height: "100%",

          img: {
            maxWidth: 550,
            height: "auto",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            margin: "auto",
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.8s, visibility 0.8s",

            "&.active": {
              opacity: 1,
              visibility: "visible",
            },
          },
        },
        ".navigator": {
          borderRadius: 12,
          background: "rgba(255, 255, 255, 0.20)",
          backdropFilter: "blur(7.5px)",
          padding: "10px 32px",
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "-600px",
          gap: 8,

          span: {
            color: "#6D7DA1",
            fontFamily: "Suisse Intl",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",

            "&:after": {
              content: '""',
              borderBottom: "solid #1b243a 1px",
              width: 0,
              display: "inline-block",
              marginBottom: 5.5,
              marginLeft: 5,
              marginRight: 5,
              transition: "width 0.8s, margin 0.8s",
            },

            "&.active": {
              color: "#1B243A",

              "&:after": {
                width: 89,
                marginLeft: 25,
                marginRight: 10,
              },
            },
          },
        },
      },
      ".meta": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "stretch",
        gap: 20,
        borderRadius: 18,
        background: "rgba(255, 255, 255, 0.20)",
        backdropFilter: "blur(15px)",
        padding: "36px 40px",
        maxWidth: 469,
        width: "100%",
        minWidth: 281,

        ".product": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 40,
          height: "100%",

          h4: {
            color: "#1B243A",
            textAlign: "center",
            fontFamily: "Halvar Breitschrift",
            fontSize: 28,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "105%",
            letterSpacing: "-1.12px",
//            textTransform: "uppercase",
            marginBottom: 10,
            maxWidth: 380,
          },
          h5: {
            color: "#6D7DA1",
            textAlign: "center",
            fontFamily: "Suisse Intl",
            fontSize: 18,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "30px",
            marginBottom: 10,
          },
          p: {
            color: "#6D7DA1",
            textAlign: "center",
            fontFamily: "Suisse Intl",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",
          },
          span: {
            padding: "8px 20px",
            alignItems: "center",
            borderRadius: 88.095,
            border: "0.881px solid #6D7DA1",
            color: "#1B243A",
            fontFamily: "Suisse Intl",
            fontSize: 17,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
//            textTransform: "uppercase",
            marginBottom: 13,
          },
        },
        label: {
          textAlign: "left",
          fontFamily: "Suisse Intl",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",
          color: "#6D7DA1",
        },
        ".colours": {
          borderRadius: 12,
          border: "1px solid rgba(109, 125, 161, 0.80)",
          background: "rgba(255, 255, 255, 0.15)",
          display: "flex",
          height: 96,
          padding: "6px 24px 0px 24px",
          justifyContent: "center",
          alignItems: "center",
          flex: "1 0 0",
          backdropFilter: "blur(4px)",

          ".colour": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 6,

            span: {
              color: "#1B243A",

              textAlign: "center",
              fontFamily: "Suisse Intl",
              fontSize: 14,
              fontStyle: "normal",
              fontWeight: 400,
            },
          },
        },
        ".note": {
          ".note-wrapper": {
            borderRadius: 12,
            background: "rgba(226, 230, 238, 0.50)",
            display: "flex",
            padding: "16px 14px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 8,
            alignSelf: "stretch",
            boxShadow: "0px 4px 4px 0px #B3BCCD",

            h5: {
              color: "#6D7DA1",
              fontFamily: "Suisse Intl",
              fontSize: 12.96,
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textAlign: "left",
            },
            p: {
              color: "#6D7DA1",
              fontFamily: "Suisse Intl",
              fontSize: 12.96,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              textAlign: "left",
            },
          },
        },
        ".specs": {
          ".specs-wrapper": {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 29,
            color: "#6D7DA1",

            ".qty, .size": {
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              width: "100%",
            },

            "input, select": {
              color: "#6D7DA1",
              fontFamily: "Suisse Intl",
              fontSize: 15,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "105%",
              padding: "20px 24px",
              borderRadius: 12,
              border: "1px solid rgba(109, 125, 161, 0.80)",
              background: "rgba(255, 255, 255, 0.15)",
              backdropFilter: "blur(4px)",
              width: "100%",

              "&:focus, &:active": {
                outline: "none",
                border: "1px solid #79A6FF",
                color: "#1B243A",
                backgroundColor: "#f4f4f4",
              },
            },
            input: {
              maxWidth: "-webkit-fill-available",
            },
          },
        },
      },
    },
    [laptop]: {
      overflow: "hidden",
      ".notice": {
        marginLeft: 60,
        marginRight: 60,
        width: "auto",
      },
      ".wrapper": {
        position: "relative",

        ".gallery": {
          margin: 30,
          height: 700,
          marginTop: "-100px",
          marginBottom: "160px",

          ".arrows": {
            ".arrow-left": {},
          },
          ".images": {
            zIndex: 0,
            width: "100%",
            height: "100%",

            img: {
              maxWidth: 550,
              width: "100%",
            },
          },
          ".navigator": {
            marginBottom: 0,
            bottom: 30,
            zIndex: 1,
          },
        },
        ".meta": {
          position: "absolute",
          bottom: "-160px",
          maxWidth: "100%",
          padding: 30,
          zIndex: 2,

          "&.active": {
            ".varibles": {
              gridTemplateRows: "1fr",
              paddingLeft: 30,
              paddingRight: 30,
            },
            ".specs": {
              gridTemplateRows: "1fr",
              paddingLeft: 30,
              paddingRight: 30,
            },
            ".note": {
              gridTemplateRows: "1fr",
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,

              ".note-wrapper": {
                padding: "16px 14px",
                marginBottom: 40,
              },
            },
          },

          ".varibles": {
            transition: "all .8s",
            display: "grid",
            gridTemplateRows: "0fr",

            ".varibles-wrapper": {
              overflow: "hidden",
            },
          },
          ".specs": {
            transition: "all .8s",
            display: "grid",
            gridTemplateRows: "0fr",

            ".specs-wrapper": {
              overflow: "hidden",
            },
          },
          ".note": {
            transition: "all .8s",
            display: "grid",
            gridTemplateRows: "0fr",

            ".note-wrapper": {
              overflow: "hidden",
              padding: 0,
            },
          },
          ".order": {
            marginTop: -20,
            display: "block",
            paddingLeft: 30,
            paddingRight: 30,
          },
          ".product": {
            gridArea: "product",
            padding: 30,
            paddingTop: 0,
            paddingBottom: 0,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gridTemplateAreas: `
                'i1 i3'
                'i2 i4'`,
            justifyItems: "center",
            alignItems: "start",

            h4: {
              fontSize: 20,
              marginBottom: 0,
              textAlign: "left",
            },
            h5: {
              fontSize: 14,
              marginBottom: 0,
              textAlign: "left",
            },
            p: {
              fontSize: 11.2,
              marginBottom: 0,
              textAlign: "right",
            },
            label: {
              fontSize: 12.8,
              marginBottom: 0,
              textAlign: "right",
            },
            ".i1": {
              gridArea: "i1",
              marginRight: "auto",
            },
            ".i2": {
              gridArea: "i2",
              marginRight: "auto",
            },
            ".i3": {
              gridArea: "i3",
              marginLeft: "auto",
            },
            ".i4": {
              gridArea: "i4",
              marginLeft: "auto",
            },
          },
        },
      },
    },
    [tablet]: {
      ".wrapper": {
        ".meta": {
          ".product": {
            "h4, h5, span": {
              fontSize: 14,
            },
          },
          label: {
            fontSize: 12.8,
          },
          ".colours": {
            ".colour": {
              span: {
                fontSize: 12.8,
              },
            },
          },
        },
      },
      ".notice": {
        color: "#1B243A",
        paddingBottom: 12,
        paddingTop: 8,
        paddingLeft: 14,
        paddingRight: 14,
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "space-between",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
        position: "absolute",
        marginLeft: 30,
        marginRight: 30,
        marginTop: 30,
        top: 80,
        width: 'auto',

        "&.desktop": {
          display: "none",
        },
        "&.mobile": {
          display: "flex",
          marign: "20px !important",
          top: 60,
        },

        "&.active": {
          ".message": {
            gridTemplateRows: "1fr",
            paddingTop: 20,
          },
        },

        h5: {
          color: "#1B243A",
          textAlign: "left",
          fontFamily: "Suisse Intl",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: 600,
        },
        ".heading": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        },
        ".message": {
          transition: "all .8s",
          display: "grid",
          gridTemplateRows: "0fr",

          p: {
            color: "#1B243A",
            textAlign: "center",
            fontFamily: "Suisse Intl",
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 400,
            overflow: "hidden",
          },
        },
      },
    },
    [mobile]: {
      ".notice": {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
      },
    }

  }),
};

export default Listing;
