import { css } from "@emotion/css";

export default function Clickable({
  children = null,
  className = null,
  onClick=null,
  ariaLabel = null,
  style = null,
}) {
  return (
    <span
      aria-label={ariaLabel ? ariaLabel : ""}
      className={`clickable ${css({ cursor: "pointer" })} ${
        className ? className : ""
      }`}
      style={style ? style : {}}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => (onClick ? eventAction(e,onClick) : {})}
      onClick={(e) => onClick ? eventAction(e, onClick) : {}}
    >
      {children}
    </span>
  );
}

export const eventAction = (e, action) => {
  if (
    e.type === "click" ||
    (e.type === "keydown" && (e.key === "Space" || e.key === "Enter"))
  ) {
    action(e);
  }
};
