import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import Clickable from "../../../components/Clickable";
import IconPlus from "../../../img/svg/plus-icon.svg";
import IconMinus from "../../../img/svg/minus-icon.svg";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";


const Data = () => {
  const [active, setActive] = useState(0);
  const [faqText, setFaqText] = useState([]);

  useEffect(() => {
    client.fetch(
    `*[_type == "faqText"] {
        title,
        content,
    }`
    ).then((data) => {
      setFaqText(data);
    }).catch(console.error);
},[]);

  return (
    <div className={style.container}>
      <h2>Frequently Asked Questions</h2>
      <div className="items">
        {Object.values(faqText).map((v, k) => (
          <div className={`${k === active ? "active" : "inactive"} item`}>
            <Clickable
              className="toggle-button"
              onClick={() => setActive(k === active ? null : k)}
            >
              <img src={k === active ? IconMinus : IconPlus} alt="" />
            </Clickable>
            <h5>{v.title}</h5>
            <div>
            <span
                className="item-text"
                dangerouslySetInnerHTML={{ __html: v.content.replace(/\n/g, '<br/>') }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const style = {
  container: css({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 200,
    paddingtop: 50,
    backgroundColor: "#f4f4f4",

    h2: {
      color: "#000",
      fontFamily: "Halvar Breitschrift",
      fontSize: 75,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-3px",
      // textTransform: "lowercase",
      // background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      marginBottom: 100,
      maxWidth: 970,
    },

    ".items": {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      maxWidth: 1225,
      gap: 0,

      ".item": {
        flex: "33.33%",
        display: "block",
        width: "fit-content",
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: 80,
        borderBottom: "solid 1px #E2E9FA",
        position: "relative",
        backgroundColor: "#f4f4f4",
        overflow: "hidden",

        ".item-text": {
          color: "rgba(27, 36, 58, 0.80)",
          fontFamily: "Suisse Intl",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",
          paddingTop: 24,
        },

        p: {
          color: "rgba(27, 36, 58, 0.80)",
          fontFamily: "Suisse Intl",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "175%",
          paddingTop: 24,
        },

        h5: {
          color: "#000",
          fontFamily: "Halvar Breitschrift",
          // background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
          background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          display: "inline-block",

          // color: "#1B243A",
          fontSize: 32,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "140%",
          letterSpacing: "-1.28px",
          // textTransform: "uppercase",
        },

        ".toggle-button": {
          position: "absolute",
          top: 50,
          left: 30,
          width: "100%",
          height: "100%",
        },

        div: {
          display: "grid",
          gridTemplateRows: "0fr",
          transition: "grid-template-rows 500ms",

          span: {
            overflow: "hidden",
          },
        },

        "&.active": {
          div: {
            gridTemplateRows: "1fr",
          },
        },
      },
    },
    [laptop]: {
      h2: {
        fontSize: 68,
      },
      ".items": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        maxWidth: 1225,
        gap: 0,

        ".item": {
          p: {
            fontSize: 16,
          },
          h5: {
            fontSize: 28,
          },
        },
      },
    },
    [tablet]: {
      paddingLeft: 30,
      paddingRight: 30,

      h2: {
        fontSize: 46,
        marginBottom: 80,
      },
      ".items": {
        maxWidth: 1225,
        gap: 0,

        ".item": {
          paddingLeft: 40,

          p: {
            fontSize: 16,
          },
          h5: {
            fontSize: 24,
          },
          ".toggle-button": {
            position: "absolute",
            top: 50,
            left: 0,
            width: "100%",
            height: "100%",
          },
        },
      },
    },
    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,

      h2: {
        fontSize: 36,
      },
      ".items": {
        maxWidth: 1225,
        gap: 0,

        ".item": {
          paddingLeft: 40,

          p: {
            fontSize: 16,
          },
          h5: {
            fontSize: 22,
          },
          ".toggle-button": {
            position: "absolute",
            top: 50,
            left: 0,
            width: "100%",
            height: "100%",
          },
        },
      },
    },
  }),
};

export default Data;
